import { LiaStarSolid } from "react-icons/lia";
import {  StarGreen } from "../../assets";
import UnlockUnlimited from "../ui/unlockUnlimited";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Pagination, Navigation, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation"; 

const HomeBanner = () => { 

  return (
    <>
      <div className="bg-black text-white relative">
        <div className="container m-auto flex flex-col items-center justify-around ">
          <header className="flex flex-col pt-[5%] lg:flex-row justify-between items-start gap-6 lg:gap-28 ">
            <h3 className="text-tertiary-white md:text-4xl flex-auto w-3/5">
              What our awesome customers say
            </h3>
          </header>
          <div
            id="testimonials"
            className="flex gap-32 items-center w-full overflow-x-scroll overflow-y-visible relative"
            style={{ scrollbarWidth: "none" }}
          >
            <Swiper
              slidesPerView={2}
              spaceBetween={30}
              autoplay={{
                delay: 7000,
                disableOnInteraction: true,
                pauseOnMouseEnter: true,
              }}
              keyboard={{
                enabled: true,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Keyboard, Pagination, Navigation, Autoplay]}
              className="mySwiper mx-8"
            >
              {testimonials.map((item, index) => (
                <SwiperSlide className="h-full p-14" key={index}>
                  <div
                    id="testimonial-cards"
                    className="p-10 border-[1px] border-gray-100 flex-auto min-w-[480px] lg:min-w-[650px] flex flex-col gap-8 custom-box_shadow-light"
                  >
                    <div className="flex ">
                      {[1, 2, 3, 4, 5].map((items, index) => (
                        <LiaStarSolid
                          key={index}
                          className="w-6 aspect-square fill-primary"
                        />
                      ))}
                    </div>

                    <p className="lg:text2xl">{item.comment}</p>

                    <footer className="flex items-center justify-between">
                      <div className="">
                        <h4 className="font-semibold text-xl">
                          {item.customer}
                        </h4>
                        <h6 className="text-gray-100">{item.address}</h6>
                      </div>
                    </footer>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <figure className="w-16 aspect-square absolute top-[5%] left-3/4 lg:left-1/3">
          <img src={StarGreen} alt="" className="h-full w-full object-cover" />
        </figure>

        <UnlockUnlimited />
      </div>
      {/*  */}
    </>
  );
};

export default HomeBanner;

const testimonials = [
  {
    comment: "It is super fast",
    customer: "Elizabeth Akenua",
    address: "Ikate, Elegushi Street, Lekki",
  },
  {
    comment: " It is very fast and reliable",
    customer: "Busayo Sanni",
    address: "Ojulari Drive, Lekki",
  },
];

export const videoTestimonials = [
  {
    comment: "It is super fast",
    customer: "Elizabeth Akenua",
    address: "Ikate, Elegushi Street, Lekki",
    src: "https://res.cloudinary.com/rdtizeti/video/upload/v1713971336/WhatsApp_Video_2024-04-24_at_15.48.09_o5k8ou.mp4",
  },
  {
    comment: " It is very fast and reliable",
    customer: "Busayo Sanni",
    address: "Ojulari Drive, Lekki",
    src: "https://res.cloudinary.com/rdtizeti/video/upload/v1713971474/WhatsApp_Video_2024-04-24_at_15.48.06_ip2oyp.mp4",
  },
];

import React from "react";
import { useState } from "react";
import { useLocation } from "react-router";
import Scheduler from "../components/scheduler/scheduler";
import ScrollerTop from "../utils/utilities";
import { fiberPlans } from "../utils/mocks/pricingMocks";
import { Suspense } from "react";
import { MapOverlay } from "../components/coverage/mapService";
import SignupForm from "../components/signupPage/signupForm";

const SignUp = () => {
  ScrollerTop();

  const [info, setInfo] = useState("");
  const { state: item } = useLocation();
  const [schedule, setSchedule] = useState(false);
  const data = fiberPlans.find((plan) => plan.speed === item?.speed);

  return (
    <div className="my-10 w-screen lg:w-4/5 m-auto bg-[#F9F9F9]">
      {!schedule ? (
        <Suspense fallback={<MapOverlay />}>
          <SignupForm
            data={data}
            setSchedule={setSchedule}
            info={info}
            setInfo={setInfo}
          />
        </Suspense>
      ) : (
        <Scheduler info={info} />
      )}
    </div>
  );
};

export default SignUp;

import React, { useEffect, useState } from "react";

import { handleAddressSearch } from "../../utils/actions";

import { MapContainer, Marker, Polygon, Popup, TileLayer } from "react-leaflet";
import Input from "../Input";
import MapGuide from "./MapGuide";
import {
  MapOverlay,
  ResetMapCenter,
  availableFillColor,
  yetFillColor,
} from "./mapService";

export const Map = ({
  address,
  isSearching,
  yetToBeAvailable,
  availableAreas,
}) => {
  const [isLoading, setIsLoading] = useState(!!isSearching);
  const [coverageDetails, setCoverageDetails] = useState();
  const [markerPosition, setMarkerPosition] = useState({ zoom: 14 });

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedHandleAddressSearch = debounce(handleAddressSearch, 4000);

  const handleInputChange = (event) => {
    const { value } = event.target;
    debouncedHandleAddressSearch(
      value,
      setMarkerPosition,
      setCoverageDetails,
      setIsLoading,
      yetToBeAvailable,
      availableAreas
    );
  };

  useEffect(() => {
    if (address?.trim().length > 1) {
      debouncedHandleAddressSearch(
        address,
        setMarkerPosition,
        setCoverageDetails,
        setIsLoading
      );
    }
    // eslint-disable-next-line
  }, [address]);


  return (
    <div className="w-screen h-[85vh] aspect-video mx-auto ">
      {isLoading && <MapOverlay />}

      <div
        className={`h-full w-full m-auto relative overflow-hidden ${
          isLoading ? "opacity-20" : " "
        }`}
      >
        <div
          className="w-2/5 mx-auto relative top-20"
          style={{ zIndex: "999" }}
        >
          <Input
            type="text"
            disabled={isLoading}
            defaultValue={address}
            placeholder="Search for coverage areas"
            onChange={handleInputChange}
          />
        </div>
        <MapContainer
          center={{ lat: 6.44168, lng: 3.482709 }}
          zoom={markerPosition.zoom}
          scrollWheelZoom={false}
          className="h-full w-full relative"
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            className="relative"
          />

          <Polygon
            positions={yetToBeAvailable}
            pathOptions={yetFillColor}
            fillOpacity={0.5}
          >
            <Popup>
              <p className="font-bold text-xs">
                Fiber will be available here soon..
              </p>
            </Popup>
          </Polygon>

          <Polygon
            positions={availableAreas}
            pathOptions={availableFillColor}
            fillOpacity={0.5}
          >
            <Popup>
              <p className="font-bold text-xs">
                Fiber is available here.
              </p>
            </Popup>
          </Polygon>

          {markerPosition.coordinate && (
            <Marker position={markerPosition.coordinate} draggable={false}>
              <Popup>
                <p>{coverageDetails?.message}</p>
              </Popup>
            </Marker>
          )}
          <ResetMapCenter centerPosition={markerPosition.coordinate} />
        </MapContainer>
      </div>

      {markerPosition.coordinate && (
        <div
          className={` bg-white px-4 py-2 fixed bottom-[10%] left-[50%] -translate-x-1/2  shadow-md border-2 rounded-lg animate-pulse ${
            coverageDetails?.status ? "border-primary" : "border-red-500"
          }`}
          style={{ zIndex: "999" }}
        >
          <p className="text-base">{coverageDetails?.message || "Fiber is not available"}</p>
        </div>
      )}
      <MapGuide />
    </div>
  );
};

import { useLocation } from "react-router-dom";
import { Map } from "../components/coverage/Map";
import ScrollerTop from "../utils/utilities";
import { GetAvailabelAreas } from "../utils/mocks/covergaeAreas";
import { MapOverlay } from "../components/coverage/mapService";
import { Suspense } from "react";

function Coverage() {
  ScrollerTop();

  const { state } = useLocation();
  const address = state?.address?.address;
  // Check if address is provided and if it isloading is set to true else false.
  const isSearching = state?.address?.address ? state?.isloading : false;

  const { availableAreas, yetToBeAvailable, gettingAvailableAreas } =
    GetAvailabelAreas();

  return (
    <div className="m-0 flex flex-col relative overflow-hidden w-screen">
      <Suspense fallback={<MapOverlay />}>
        <Map
          address={address}
          isSearching={isSearching || gettingAvailableAreas}
          availableAreas={availableAreas}
          yetToBeAvailable={yetToBeAvailable}
        />
      </Suspense>
    </div>
  );
}

export default Coverage;

import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  Schedule,
  useCheckAvailableDates,
} from "../../utils/actions";
import qs from "qs";
import loader from "../../assets/gif/TizetiLoaderColored.gif";
import dayjs from "dayjs";
import moment from "moment";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";

const Scheduler = ({ info }) => {
  const { getAvailableDates, getNextAvailableDates } = useCheckAvailableDates();
  const [value, onChange] = useState(new Date());
  const [notes, setNotes] = useState("");
  const [device, setDevice] = useState(null);
  const formattedToday = moment().add(1, "day").format("YYYY-MM-DD");
  const [isScheduling, setIsScheduling] = useState(false);
  const [date, setDate_a] = useState({
    isFetching: false,
    items: [],
    date_a: new Date(),
    date_h: new Date(),
  });

  const today = qs.stringify({
    date: formattedToday,
    zone_id: info.zone_id,
  });

  const nextdate = qs.stringify({
    date: date.date_h,
    zone_id: info.zone_id,
  });



  //get available Text

  const {
    data: available_dates,
    isPending: getting_available_dates,
    error: cannot_fetch_avilable_dates,
  } = useQuery({
    queryKey: [""],
    queryFn: () => getAvailableDates(today),
  });

  useEffect(() => {
    if (
      !getting_available_dates &&
      !cannot_fetch_avilable_dates &&
      available_dates
    ) {
      setDate_a((prev) => {
        return {
          ...prev,
          items: available_dates.data,
          date_a: available_dates.data[0].date,
          date_h: available_dates.data[4].date,
        };
      });
    }
  }, [available_dates, cannot_fetch_avilable_dates, getting_available_dates]);

  // Calculate device count
  const { items } = date;
  useEffect(() => {
    const itemCount = items.find((item) => item.installationCount);
    if (itemCount) {
      setDevice(itemCount.installationCount);
    }
  }, [items]);

  const handleGetNextAvailableDates = () => {
    setDate_a((prev) => {
      return { ...prev, isFetching: true };
    });
    try {
      getNextAvailableDates(nextdate).then((res) => {
        setDate_a((prev) => {
          return {
            ...prev,
            isFetching: false,
            items: res.data,
            date_a: res.data[0].date,
            date_h: res.data[4].date,
          };
        });
      });
    } catch (err) {
      toast.warn("Could not Fetch Next Available Date");
      setDate_a((prev) => {
        return { ...prev, isFetching: false };
      });
    }
  };

  const data = {
    address: info.address,
    address2: info.formatted_address,
    state: info.state,
    date: moment(value).format("YYYY-MM-DD"),
    payment_reference: info.payment_referencey,
    sendCustomerMail: true,
    user_id: info.id,
    zone_id: info.zone_id,
    category_id: 11,
    sub_zone_id: info.sub_zone_id,
    country: "ng",
    installation_notes: notes,
  };


  const onSubmit = async (e) => {
    e.preventDefault();
    if (data) {
      Schedule(data, setIsScheduling);
    }
  };

  return (
    <div className="flex flex-col lg:flex-row gap-10">
      {!items.length ? (
        <img src={loader} alt="Loading..." />
      ) : (
        <form className="form mt-3 lg:w-3/5 m-auto" onSubmit={onSubmit}>
          <div className="flex flex-col justify-between gap-10">
            <div className="">
              <Calendar
                defaultView="month"
                minDetail="month"
                minDate={new Date(date.date_a)}
                maxDate={new Date(date.date_h)}
                onChange={onChange}
                value={value}
                tileDisabled={({ date }) => {
                  const dateTileInstance = dayjs(date);
                  const itemExists = items.find((item) =>
                    dayjs(item.date).isSame(dateTileInstance)
                  );

                  if (!itemExists) return false;

                  const day = dateTileInstance.get("day");
                  const isWeekend = day === 0;
                  const countIsMoreThan3 = itemExists.count > device;
                  const noDevice = device;

                  if (isWeekend || countIsMoreThan3 || !noDevice) return true;
                  return false;
                }}
              />

              <button
                className={`py-2 px-2 border-2 rounded-lg my-7 w-fit  ${date.isFetching ? 'bg-red-600 text-white' : "border-primary-light text-primary"}`}
                onClick={handleGetNextAvailableDates}
                disabled={date.isFetching}
                type="button"
              >
                {date.isFetching ? "Fetching..." : "Check Next Available Date"}
              </button>
            </div>

            <div className="">
              <div className="">
                <label className="text-gray-400/70">
                  Installation notes
                </label>
                <textarea
                  name="notes"
                  cols={30}
                  rows={12}
                  className="w-full border-4 border-primary py-2 px-4 "
                  placeholder="notes"
                  onBlur={(e) => setNotes(e.target.value)}
                  required
                ></textarea>
              </div>

              <button
                className={`button-primary ${!isScheduling ? "bg-primary-light" : "bg-gray-400"}  my-3 ml-auto`}
                type="submit"
                disabled={isScheduling}
              >
                {isScheduling ? "Submitting..." : "Submit"}
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default Scheduler;

import ScrollerTop from "../utils/utilities";

 

 

const UserAgreement = () => {
  ScrollerTop();

  return (
    <div className=" w-4/5 m-auto">
      <header className="page-header">
        <h3 className="">User agreement</h3>

        <p className="px-4">
          Please read the Terms and Conditions provided below carefully. These
          terms set forth the legally binding terms and conditions for the use
          of Tizeti’s Broadband Services (the Services) and these terms shall
          govern your use of the Services for the period which you are
          subscribed to use the Services. Kindly note that these terms may
          change from time to time and shall apply in respect of all the
          Services.
        </p>
      </header>

      <ol className="list-decimal flex flex-col gap-4 mt-6">
        <li>
          <header>
            <h3 className="uppercase font-bold lg:text-2xl text-dark-chocolate">Definitions</h3>
          </header>
          <p>
            In these Terms, unless the context otherwise provides, the following
            words shall have the following meanings: “Agreement” means this
            Terms and Conditions as read, understood and signed by the Customer,
            applicable from time to time;
          </p>
          <p>
            “Channel Partner” means a company that partners with Tizeti to sell
            the Services;
          </p>
          <p>
            “Customer” means any person or organization who has agreed to
            utilize any of Tizeti’s Services
          </p>
          <p>
            “Customer” means any person or organization who has agreed to
            utilize any of Tizeti’s Services pursuant to these Terms and
            Conditions;
          </p>
          <p>
            “Government Authority” means any national, supranational, regional
            or local government or governmental, legislative, administrative,
            fiscal, judicial or government-owned body, department, commission,
            authority, tribunal, agency or other entity exercising executive,
            legislative, judicial, regulatory or administrative powers or
            functions of or pertaining to government or any subdivision thereof,
            including any duly authorized law enforcement agencies; and
          </p>
          <p>
            “Services” means the provision of internet to its Customer and
            includes all service packages, service plans, schemes and offers
            introduced by Tizeti from time to time.
          </p>
        </li>
        <li>
          <header>
            <h3 className="uppercase font-bold lg:text-2xl text-dark-chocolate"> OUR SERVICE SCOPE</h3>
          </header>
          <ol>
            <li>
              <p>
                2.1. Tizeti shall provide Broadband Access Services to the
                Customer for the period which the Customer has subscribed to the
                use of the Services.
              </p>
              <p>
                2.2. Tizeti reserves the right to withdraw, amend, modify or
                delete account related codes, IDs, email ID, user id, or
                passwords/s allocated/assigned/shared by Tizeti to the Customer,
                at any time without assigning any reason thereof.
              </p>
              <p>
                2.3. The Services remain exclusive property of TIZETI, and the
                Customer is authorized to use, only during the sustenance of
                this Agreement. The right to use all Services, Application or
                features or Access parameters including but not limited to email
                ID, user ID, password etc. as set out under the services, will
                cease to exist on the day your Agreement expires with Tizeti.
              </p>
              <p>
                2.4. Tizeti reserves the right to withdraw, amend, modify or
                delete Services/s, Service packages, Service plans, Schemes,
                Offers, at any time without assigning any reasons thereof,
                provided that notice of such action shall be given to the User
                at a reasonable time thereafter.
              </p>
              <p>
                2.5. Tizeti shall not be liable for any loss, cost or damage
                arising from transactions the Customer enters into with third
                parties using the Services (such transactions shall include and
                are not limited to buying or renting goods or services from such
                third parties or ordering goods from other organizations using
                our internet services).
              </p>
              <p>
                2.6. Neither Tizeti nor the Channel Partners warrants that the
                Services will be uninterrupted or error-free or that any
                information, software or other material accessible on the
                Service isfree of viruses, worms, Trojan horses or other harmful
                components.
              </p>
              <p>
                2.7. Neither Tizeti nor the Channel Partner shall be responsible
                for the installation of hardware or software needed for the
                Service package and the Customer shall ensure that competent
                persons have satisfactorily done the same. Without prejudice to
                the foregoing Tizeti or the Channel Partner will render customer
                advisory service sans recourse.
              </p>
              <p>
                2.8. Tizeti or its Channel partners shall not be responsible for
                compatibility problem(s) of hardware/software at the customer
                premises. The customer herewith expressly acknowledges and
                agrees that installation of applications at its hardware shall
                be carried out by a competent person and Tizeti shall not be
                liable for any loss or damage to Customer’s hardware
                (PC/Computer/Server) during the course of such installation of
                sustenance of this Agreement.
              </p>
              <p>
                Tizeti accepts no responsibility whatsoever for the content of
                any material from other websites, organizations, which may be
                accessed through the Services.
              </p>
              <p>
                2.10. Tizeti reserves the right to block access to any such
                information which is not permissible as per the law of the
                Federal Republic of Nigeria and/or as directed by the authorized
                honourable representative offices of Federal Government of
                Nigeria.
              </p>
              <p>
                2.11. Our day commences on 00:00:00 am and ends in another
                23:59:59pm and internet expire 00:00:00 of the expiration day.
              </p>
              <p>
                2.12. The devices belong to the company and will be withdrawn
                from the customer if the customer account is expired for 90
                days.
              </p>
              <ol>
                <li>
                  <p>
                    2.12.1 For installations done based on promotional prize
                    offers, devices will be withdrawn if account is expired for
                    7days.
                  </p>
                </li>
              </ol>
              <p>
                2.13. Upon damage to the device, the customer takes full
                responsibility. Prices for replacement of devices are subject to
                market forces.
              </p>
              <p>
                2.14. However, the safety of the devices is the customer’s
                responsibility. Hence, Customer is advised to take full
                responsibility and its safety precaution in safe-guarding the
                device.
              </p>
              <p>
                2.15. Unless within the reasonable confines of any applicable
                law, no member of our staff shall be held hostage.
              </p>
              <p>
                2.16. The relocation of a fixed CPE is subject to cost and must
                be within the coverage area.
              </p>
              <p>
                2.17. Upon no coverage, Customer will not be refunded on setup
                if the customer has already been installed and confirmed the
                internet is working or refund of subscription.
              </p>
              <p>
                2.18. Customer’s card will be charged within 7 days if our
                device has not been retrieved. The Customer is required to
                ensure that the registered card can be charged as the need
                arises, failing which legal action may be taken.
              </p>
              <p>
                2.19. A customer account cannot be suspended or put on hold once
                payment is made.
              </p>
              <p>
                2.20. There will be multiple SSID on your device for test and
                development. If for any reason you do not want this, there will
                be a charge to this.
              </p>
              <p>
                2.21. Tizeti has the right to apply strict restrictions to any
                site depending on the demand on the network.
              </p>
              <p>
                2.22. Tizeti reserves the right to upgrade the Network/ capacity
                so as to provide the desired speed of Services to the Customer,
                without prior information either to the Customer or the Channel
                partner. However, the customer understands and agrees that
                Tizeti services may be affected by factors including but not
                limited to the type of equipment being used by you, the internet
                site/server being visited, network availability, the number of
                users accessing the internet network in an area/Location;
                strength of the signal and equipment distance from the nearest
                antenna and ecological conditions which shall impact operation
                at the desired speed at all times.
              </p>
              <p>
                2.23. The customer understands further that the internet
                contains unedited materials, some of which are sexually explicit
                or may be offensive to some people. Customer’s access to such
                materials will be at his own risk. Tizeti or the Channel
                partners have no control over the same and accepts no
                responsibility whatsoever or such materials.
              </p>
              <p>
                2.24. Tizeti observes a zero-tolerance policy regarding the
                delivery of Spam or unwanted commercial messages using the
                Service. Notwithstanding anything else in this agreement, the
                Customer will not send, cause to be sent or facilitate, Spam
                using the Service. Accounts that send Spam or that we believe
                may be facilitating Spam may be suspended and terminated and
                Tizeti will work with the proper authorities in the event of a
                prosecution.
              </p>
              <p>
                2.25. Tizeti or its channel partner reserves the right to
                release details of a customer to any security agents, as
                required by law, in a case the account was used to commit fraud
              </p>
              <p>
                2.26. Tizeti or its Channel partner will not be held responsible
                if the service is used for impersonation.
              </p>
              <p>
                2.27. Tizeti requires the customer to provide information on the
                customer’s profile at the time of sign up.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <header>
            <h3 className="uppercase font-bold lg:text-2xl text-dark-chocolate">PRIVACY</h3>
          </header>
          <ol>
            <li>
              <p>
                3.1. Tizeti may provide Customer’s information, in response to a
                request from Government Authority or other website
                administrators, to detect and prevent security incidents, crime
                and prosecute offenders. In line with the relevant laws of the
                Federal Republic of Nigeria, Tizeti may also provide information
                to protect national security.
              </p>
              <p>
                3.2. Additional information relating to privacy shall be
                contained in Tizeti’s Privacy Policy.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <header>
            <h3 className="uppercase font-bold lg:text-2xl text-dark-chocolate">CUSTOMER’S OBLIGATIONS </h3>
          </header>
          <p>
            You may not use the Services in a way that risks degradation of
            services levels to other customers, third parties and put our system
            at risk. If we believe that you are using our Services in any of
            these ways, as mentioned, we are entitled to reduce, suspend and/or
            terminate any or all of our Services without giving you prior
            notice.
          </p>
        </li>
        <li>
          <header>
            <h3 className="uppercase font-bold lg:text-2xl text-dark-chocolate">FORCE MAJEURE</h3>
          </header>
          <p>
            Tizeti is not liable for failure to perform its obligation if such
            failure arises as a result of a Force Majeure which includes (but is
            not limited to) acts of God (including fire, flood, earthquake,
            storm, hurricane or other natural disasters), war, invasion, an act
            of foreign enemies, hostilities (regardless of whether war is
            declared), civil war, rebellion, revolution, insurrection, military
            or usurped power or confiscation, terrorist activities,
            nationalization, government sanction, blockage, embargo, labour
            dispute, strike, lockout or industrial disputes or interruption or
            failure of electricity or telecommunications service; actions of the
            regulator or local or national governments or other authorities.
          </p>
        </li>
        <li>
          <header>
            <h3 className="uppercase font-bold lg:text-2xl text-dark-chocolate">SUSPENSION OF SERVICES</h3>
          </header>
          <p>
            Tizeti may suspend any or all of Services immediately without notice
            if: payments made to Tizeti have not been realized for any reason
            due to Customer’s fault; Tizeti has to do so by law or in line with
            an Agreement; Tizeti has reason to believe that the Customer has
            provided Tizeti with false, inaccurate or misleading information
            either for the purpose of obtaining our Services and/or Access
            Devices; Tizeti believes that the Customer or another person at the
            Customer’s office or home has committed, or maybe committing, any
            act which contravenes the terms of the Services Agreement by using
            the Services; or Tizeti needs to carry out any maintenance, repairs
            or improvements to any part of its services or its systems, provided
            that reasonable notice may be given for this purpose.
          </p>
        </li>
        <li>
          <header>
            <h3 className="uppercase font-bold lg:text-2xl text-dark-chocolate">DISCLAIMER</h3>
          </header>
          <p>
            While every effort is made by Tizeti to provide the highest quality
            of Service, the Customer acknowledges that Tizeti network is
            interconnected with high-speed internet links provided by other
            service providers/ entities that are responsible to maintain these
            links as reliable as possible. Tizeti does not own any
            responsibility in case of interruptions in the inter-connect network
            beyond its reasonable control. Tizeti shall not be responsible
            whatsoever for any interruption caused by the quality of these
            links, defect in connectivity or any inconvenience, damages or any
            other liability whatsoever wither from the Customer or anyone else
            in this regard.
          </p>
        </li>
        <li>
          <header>
            <h3 className="uppercase font-bold lg:text-2xl text-dark-chocolate">
              AMENDMENT OF TERMS AND CONDITIONS
            </h3>
          </header>
          <p>
            Tizeti may at any time improve, modify, amend or alter the terms and
            conditions of the Services if there is any change or amendment to
            any law or regulation which applies to Tizeti or the Services;
            Tizeti decides that the Services should be altered for reasons of
            quality of service or otherwise for the benefit of its customers or,
            in Tizeti’s reasonable option, it is necessary to do so; for
            security, technical or operational reasons; in all other events,
            where Tizeti reasonably determines that any modification to its
            system or change in its trading, operating or business practice or
            policy is necessary to maintain or improve the Services.
          </p>
        </li>
        <li>
          <header>
            <h3 className="uppercase font-bold lg:text-2xl text-dark-chocolate">DISPUTES</h3>
          </header>
          <p>
            Any dispute that may arise from the interpretation or application of
            these terms and conditions, shall where required, be adjudicated by
            a competent Court sitting in Lagos and the governing law shall be
            the Laws of the Federal Republic of Nigeria.
          </p>
        </li>
      </ol>
    </div>
  );
};

export default UserAgreement;

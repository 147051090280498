export const PAYSTACK_TEST_KEY =
  "pk_test_e1929473ff607af44d68b320871309eba86d377f";

export const PAYSTACK_LIVE_KEY = 
  "pk_live_48dd0c126b30e969b1b7eff9f27ded163647f4c6";

 export const GoogleaAPIKey = "AIzaSyDVj1HKb0dsVhqA9ZYXgQTc7hWk19vFNtg";

export const VERIFICATION_URL_TEST =
  "https://api.tizeti.com/api/v1/paystack/verifyTest"; 

export const VERIFICATION_URL =
  "https://api.paystack.co/transaction/verify/"; 

export const REGISTER_NEWUSER = "https://api.tizeti.com/api/v1/users/newuser";

export const API_URL = "https://api.tizeti.com/api/v1";

export const CHECK_AVAILABLE_DATES_URL = `${API_URL}/checkavailabledate`;

export const LOGIN_URL = `${API_URL}/employeelogin`;
export const CITIES_STATES = "https://api.tizeti.com/api/v1/legacy/get_cities_by_state?state="
export const REGIONS = "https://api.tizeti.com/api/v1/legacy/get_list_of_regions"
export const  UPLOAD_VIDEO = "https://api.tizeti.com/api/v1/fibreVideo/upload"

import { Dart, Fiber, WirelessFidelity } from "../assets";
import UnlockUnlimited from "../components/ui/unlockUnlimited";
import ScrollerTop from "../utils/utilities";

const About = () => {
  ScrollerTop();

  return (
    <div className="m-0">
      <header className="page-header ">
        <h3 className="text-tertiary text-center w-3/5 m-auto capitalize page-header ">
          About FreeFiber
        </h3>
      </header>

      <main className="flex flex-col gap-14 w-full items-center">
        <div className="md:grid flex flex-col grid-cols-2 auto-rows-auto grid-flow-col-dense gap-4 w-4/5 m-auto ">
          <div className="row-span-2  bg-gray-200 rounded-lg flex flex-col gap-4">
            <header className="flex flex-col md:items-start justify-between gap-32">
              <figure className="w-3/4">
                <img src={Fiber} alt="" className="image-cover" />
              </figure>
              <h3 className="text-tertiary px-4">About FreeFiber</h3>
            </header>
            <p className="p-4">
              Embrace the future of connectivity with FreeFiber. Our
              cutting-edge Fiber-optic network delivers more than just
              high-speed internet; it brings a revolution in reliable, fast, and
              cost-free connectivity to your home or office.
            </p>
          </div>

          <div className="bg-primary-light rounded-lg flex flex-col gap-4">
            <header className="flex items-end justify-between">
              <h3 className="text-tertiary px-4 w-2/3 capitalize">
                About Tizeti
              </h3>
              <figure className="w-1/3">
                <img src={WirelessFidelity} alt="" className="image-cover" />
              </figure>
            </header>
            <p className="p-4">
              Tizeti provides high speed broadband internet to Residences,
              businesses, Events, Conferences and deploys public Wi-fi Hotspot
              at locations across Africa.
            </p>
          </div>
          <div className="bg-[#040404] text-[#EEE] rounded-lg flex flex-col gap-4">
            <header className="flex items-end justify-between">
              <h3 className="text-white px-4">Mission/Vision</h3>
              <figure className="w-1/3">
                <img src={Dart} alt="" className="image-cover" />
              </figure>
            </header>
            <p className="p-4">
              Building the infrastructure for Africa's digital economy. We are
              widening internet access in Africa with solar-powered towers, to
              deliver the most cost-effective and reliable unlimited internet
              services
            </p>
          </div>
        </div>

        <UnlockUnlimited />
      </main>
    </div>
  );
};

export default About;

import { toast } from "react-toastify";
import { useEffect } from "react"; 

export function checkPhoneNumber(phoneNumber) { 
  const numbers = /^[0-9]+$/;
  if (!phoneNumber.match(numbers)) {
    toast.info("Please enter only numbers");
  } else if (phoneNumber.length !== 11) {
    toast.info("invalid phone number");
  } else {
    return true;
  }
  return false;
}


export function checkEmail(emailAddress) {
 const emailRegex = /^[a-z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/g;
   if (!emailAddress.match(emailRegex)) {
    toast.info("Please enter a valid email");
   }else{
     return true;
   }
  return false;
}


export default function ScrollerTop (){
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  });
};


import { Link } from "react-router-dom"; 
import { Disclosure } from "@headlessui/react";
import { FaChevronDown } from "react-icons/fa6";  
import ScrollerTop from "../utils/utilities";
 
  
const FAQs = () => {
  ScrollerTop();

  return (
    <div className=""> 
      <header className="page-header">
        <h3 className="text-tertiary text-center">FAQs</h3>
      </header>

      <main className="w-4/5 mx-auto ">
        {faqs.map((item, index) => (
          <Disclosure key={index}>
            {({ open }) => (
              <div className=" border-b-[1px] border-gray-600">
                <Disclosure.Button className="pb-2 w-full text-2xl">
                  <header className="flex justify-between items-center py-3">
                    <h5 className="text-primary-light font-bold">{item.title}</h5>
                    <FaChevronDown
                      className={`transition-all duration-700 ${
                        open ? "rotate-180 transform" : ""
                      }`}
                    />
                  </header>
                </Disclosure.Button>

                <Disclosure.Panel className="text-[#333333 py-3 text-xl">
                  {item.link
                    ? item.link.map((item,index) => (
                        <p key={index}>
                          {item.textBeforeLink} 
                          <Link to={item.link} className="underline px-1">
                            {item.label}
                          </Link>{" "}
                          {item.textAfterLink}
                        </p>
                      ))
                    : item.body}
                </Disclosure.Panel>
              </div>
            )}
          </Disclosure>
        ))}
      </main> 
    </div>
  );
};

export default FAQs;

const faqs = [
  {
    title: "What is the Free Fiber Internet Service?",
    body: `  The Free Fiber Internet Service, provided by Tizeti Network
        Limited, offers complimentary access to high-speed internet
        connectivity through a Fiber-optic network, enabling numerous
        people online. The setup and the first-month subscription are
        completely free.`,
  },
  {
    title: "Who is eligible for the Free Fiber Internet Service?",
    body: `Eligibility is open to everyone above 18 years old who can
     afford the monthly renewal of 10,000 Naira or 20,000 Naira or above after the first free month.`,
  },
  {
    title:
      "How can I check if I am eligible for the Free Fiber Internet Service?",
    body: `  Eligibility criteria are outlined on our website and promotional materials. For personalized assistance, contact our customer support.`,
  },
  {
    title: "What are the benefits of the Free Fiber Internet Service?",
    body: ` This service provides users with high-speed internet access at no cost, allowing for seamless online experiences, including browsing, streaming, and communication.`,
  },
  {
    title: "Is there a data cap on the Free Fiber Internet Service?",
    body: ` Our Free Fiber Internet service is designed for unlimited data usage.`,
  },
  {
    title: "How do I sign up for the Free Fiber Internet Service?",
    link: [
      {
        textBeforeLink: "Visit",
        link: " www.freeFiber.com.ng/signup",
        label: " www.freeFiber.com.ng/signup",
        textAfterLink: "to sign up for the service.",
      },
    ],
  },
  {
    title: "Can I upgrade to a paid plan with additional features?",
    body: ` Yes, you are automatically upgraded to a premium paid plan
     after the free first month, depending on your chosen plan.`,
  },
  {
    title: "What happens if I move to a different location?",
    body: ` Service availability may vary by location. Contact our customer support to discuss relocation options.`,
  },
  {
    title: "Is customer support available for the Free Fiber Internet Service?",
    body: ` Yes, customer support is available to address any queries or issues
     related to the Free Fiber Internet Service. Contact details will be provided during the sign-up process.`,
  },
  {
    title:
      "How does Fiber Internet differ from other types of internet services?",
    body: ` Fiber Internet uses optical Fibers to transmit data using light 
    signals, offering faster and more reliable connections compared to DSL or cable services that use copper wires`,
  },
  {
    title: "What are the advantages of Fiber Internet?",
    body: ` Fiber Internet provides faster upload and download speeds,
     low latency, increased bandwidth, and a more stable connection, making it ideal for activities like streaming, online gaming, and video conferencing.`,
  },
  {
    title: "Is Fiber Internet available in my area?",
    body: ` Availability may vary by location. Check our website or
     contact our customer support to determine if Fiber Internet is available in your area.`,
  },
  {
    title: "How do I sign up for Fiber Internet Service?",
    body: ` You can sign up for Fiber Internet through our website or
     by contacting our customer support. Follow the provided instructions for a smooth sign-up process.`,
  },
  {
    title: "What equipment is needed for Fiber Internet installation?",
    body: ` A compatible modem and router are typically required. 
    Our installation team will provide the necessary equipment and guide you through the setup process.`,
  },
  {
    title: "Is Fiber Internet suitable for businesses?",
    body: ` Yes, Fiber Internet is highly recommended for businesses due to its reliability,
     fast speeds, and the ability to handle multiple users and devices simultaneously.`,
  },
  {
    title: "Are there data limits on Fiber Internet plans?",
    body: ` Our Fiber Internet plans often come with generous or unlimited 
    data allowances. Check specific plan details for information on data limits.`,
  },
  {
    title: "Can I use my own modem/router with Fiber Internet?",
    body: ` It is recommended to use the equipment provided by our service to ensure
     compatibility and optimal performance. Our support team can guide you on compatible devices.`,
  },
  {
    title:
      "What happens if I experience technical issues with my Fiber Internet connection?",
    body: ` Our dedicated customer support team is available to assist you. Contact
     us via phone or online support for prompt assistance in resolving any technical issues.`,
  },
];

 

export const fiberPlans = [
  {
    title: "Fiber Unlimited",
    plan : "fiber_residential",
    price: "₦16,500/month",
    amount: 16500,
    plans: "Fiber-residential",
    speed: "50 Mbps",
    caption:
      "Introducing our Starter plan—a budget-friendly option designed for seamless everyday browsing and light streaming needs. Enjoy lightning-fast 50Mbps speed, ideal for casual browsing and streaming. Plus, benefit from free installation and your first month subscription free",
    description:
      "Affordable and efficient, perfect for everyday browsing and light streaming.",
    features: [
      "Lightning-fast 50Mbps speed",
      "Ideal for casual browsing and streaming",
      "Free installation",
      "First month subscription free",
    ],
  },
  {
    title: "Fiber Pro",
    plan : "fiber_pro",
    type: "Popular",
    price: "₦99,000/6months",
    amount: 99000,
    speed: "100 Mbps",
    plans: "Fiber-residential",
    tag: "MOST POPULAR",
    caption:
      "Upgrade to our Professional plan for an enhanced online experience, featuring lightning-fast 100Mbps speed—ideal for work, entertainment, and powering multiple devices. Enjoy free installation and your first month subscription free",
    description:
      "Elevate your online experience with robust speed, ideal for work, entertainment, and multiple devices.",
    features: [
      "Lightning-fast 100Mbps speed",
      "Perfect for work, entertainment, and multiple devices",
      "Free installation",
      "First month subscription free",
    ],
  },
  {
    title: "Fiber Yearly",
    plan : "fiber_yearly",
    price: "₦198,000/year",
    amount: 19800,
    plans: "Fiber-residential",
    speed: "150 Mbps",
    caption:
      "Step into the realm of lightning-fast connectivity with our Advanced plan. Enjoy blazing 150Mbps speed, perfect for high-demand applications and activities. Plus, get free installation and your first month subscription free",
    description:
      "Unleash the power of high-demand applications and activities with lightning-fast connectivity.",
    features: [
      "Blazing 150Mbps speed",
      "Unleash the full potential of high-demand applications and activities",
      "Free installation",
      "First month subscription free",
    ],
  },
];

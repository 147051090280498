import { LuArrowDownRight } from "react-icons/lu";
import { Autoplay, Keyboard, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { videoTestimonials } from "./HomeBanner";
import { MdArrowRightAlt, MdArrowDownward } from "react-icons/md"; 

const WhyFiber = () => {

  return (
    <>
      <ul className="list-none flex flex-row items-center justify-between w-full ">
        {FiberBenefits.map((item, index) => {
          return (
            <li
              className="flex items-stretch gap-6 border-gray-400 list-horizontal"
              key={index}
            >
              <div className="mx-auto flex items-center p-4">
                <LuArrowDownRight className="text-4xl lg:text-8xl hidden lg:block" />
                <span className="flex flex-col">
                  <div className="flex items-center order-8 lg:order-1">
                    <LuArrowDownRight className="text-4xl lg:hidden" />
                    <h4 className="text-base lg:text-3xl font-semibold">
                      {item.title}
                    </h4>
                  </div>
                  <h6 className="order-2 heading-subtitle">{item.subTitle}</h6>
                </span>
              </div>
            </li>
          );
        })}
      </ul>
      <div
        id="why-Fiber"
        className="flex items-center justify-between w-4/5 gap-12 flex-col lg:flex-row mt-8"
      >
        <article className="flex flex-col gap-9 lg:w-2/5 text-dark-chocolate">
          <header className="lg:w-4/5">
            <div className="text-tertiary">
            <h3 className="underline inline">What customers think about FreeFiber</h3>
            <MdArrowRightAlt className="text-5xl hidden lg:inline" />
            <MdArrowDownward className=" inline text-5xl lg:hidden" />
            </div>
          </header>
          <p className="lg:text-xl ">
            The speeds are consistently fast, even during peak times when
            everyone is online. We offer the fastest Gigabit network available.
          </p>
          <div
            to="/about"
            className="flex items-end gap-2 mx-auto lg:ml-auto lg:mr-0"
          >
          
          </div>
        </article>

        <div
          id="testimonial_videos"
          className="flex gap-32 items-center w-full lg:w-1/2 overflow-x-scroll overflow-y-visible relative"
          style={{ scrollbarWidth: "none" }}
        >
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            autoplay={{
              delay: 7000,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            keyboard={{
              enabled: true,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Keyboard, Pagination, Navigation, Autoplay]}
            className="mySwiper mx-8"
          >
            {videoTestimonials.map((item, index) => (
              <SwiperSlide className="h-full p-14" key={index}>
                <div
                  id="testimonial-cards"
                  className=" flex-auto min-w-[480px] lg:min-w-[550px] w-full flex flex-col items-center justify-center md:flex-row gap-8 relative"
                >
                  <video
                    className="lg:w-[40rem] overflow-hidden aspect-video relative" 
                    controls
                    controlsList="nofullscreen nodownload noremoteplayback"
                  >
                    <source
                      className="h-full w-full object-cover"
                      src={item.src}
                    />
                  </video>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default WhyFiber;

const FiberBenefits = [
  {
    title: "1GPS",
    subTitle: "Download Speeds",
  },

  {
    title: "1-Month",
    subTitle: "Subscription free",
  },

  {
    title: "Zero",
    subTitle: "Installation fees",
  },
];

import { Link } from "react-router-dom";
import { WorldStar } from "../../assets"; 

const UnlockUnlimited = () => {
  return (
    <>
      <div className="text-center relative pt-28 text-white bg-black w-full">
        <p className="lg:w-4/5 px-[5%] mx-auto text-7xl text-center">
          Unlock Unlimited speeds right now!
        </p>

        <Link to="/coverage" className="text-center">
          <button className="button-tertiary border-transparent my-10 bg-[#C72327] animate-pulse hover:animate-none hover:-translate-y-2 duration-100 ease-in-out">start now</button>
        </Link>

        <figure className="w-40 lg:w-60 aspect-video  bottom-0 left-0">
          <img src={WorldStar} alt="" className="h-full w-full object-cover" />
        </figure>
      </div>
    </>
  );
};

export default UnlockUnlimited;

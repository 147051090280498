import { useState } from "react";
import { Link } from "react-router-dom";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Input from "../Input";

export default function HeroSection() {
  const [address, setAddress] = useState("");
  const handleChangeAddress = (address) => setAddress({ address });

  const handleAddressSelect = async (address) => {
    try {
      const result = await geocodeByAddress(address);
      const latLng = await getLatLng(result[0]);

      setAddress({ address, direction: latLng });
    } catch (error) {}
  };

  return (
    <>
      <div
        className="min-h-[80vh] w-screen bg-home-hero bg-cover bg-center bg-no-repeat flex items-center justify-center"
        id="home-hero"
      >
        <div className=" px-[10%] lg:px-[20%] flex flex-col gap-20 justify-between">
          <h2 className="capitalize text-white font-semibold text-3xl lg:text-6xl text-center">
            Reliable Fiber Connectivity, Zero Barriers: Embrace the Future with
            FreeFiber.
          </h2>

          <div className="flex justify-around w-3/5 flex-col mx-auto relative gap-3">
            <div className="px-2">
              <PlacesAutocomplete
                name="address"
                value={address ? address.address : ""}
                onChange={handleChangeAddress}
                onSelect={handleAddressSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <Input
                      {...getInputProps({
                        label: "",
                        placeholder: "Search Places ...",
                        name: "address",
                        autoComplete: "off",
                      })}
                      required
                    />
                    <div className="autocomplete-dropdown-container w-full flex flex-col gap-2 bg-slate-200 rounded-lg overflow-hidden  h-fit">
                      {loading && <div>Loading Addresses...</div>}
                      {suggestions.map((suggestion, index) => (
                        <div
                          {...getSuggestionItemProps(suggestion)}
                          className={`${
                            suggestion.active ? "bg-[#fafafa]" : "bg-[#ffffff]"
                          } cursor-pointer, p-2`}
                          key={index}
                        >
                          <p className="cursor-pointer text-sm inline">
                            {suggestion?.formattedSuggestion.mainText},
                            <span className="text-xs text-gray-400 inline pl-1">
                              {suggestion?.formattedSuggestion?.secondaryText}
                            </span>
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>

            <Link
              to="/coverage"
              className="bg-primary-light text-white border border-gray-700 rounded-lg shadow-sm px-4 py-3 w-fit mx-auto"
              state={{ address, isloading: !address.length || true }}
            >
              Check Availabilty
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

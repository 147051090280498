import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import Coverage from "./pages/Coverage";
import SignUp from "./pages/SignUp";
import About from "./pages/About";
import FAQs from "./pages/FAQs";
import UserAgreement from "./pages/UserAgreement";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Root from "./roots/roots";
import NotFound from "./pages/NotFound";
import { MapOverlay } from "./components/coverage/mapService";
import { Suspense } from "react";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<MapOverlay />}>
              <Home />
            </Suspense>
          ),
        },
        {
          path: "/coverage",
          element: (
            <Suspense fallback={<MapOverlay />}>
              <Coverage />
            </Suspense>
          ),
        },
        {
          path: "/signup",
          element: (
            <Suspense fallback={<MapOverlay />}>
              <SignUp />
            </Suspense>
          ),
        },
        {
          path: "/about",
          element: (
            <Suspense fallback={<MapOverlay />}>
              <About />
            </Suspense>
          ),
        },
        {
          path: "/FAQs",
          element: (
            <Suspense fallback={<MapOverlay />}>
              <FAQs />
            </Suspense>
          ),
        },
        {
          path: "/userAgreement",
          element: (
            <Suspense fallback={<MapOverlay />}>
              <UserAgreement />
            </Suspense>
          ),
        },
        {
          path: "/privacyPolicy",
          element: (
            <Suspense fallback={<MapOverlay />}>
              <PrivacyPolicy />
            </Suspense>
          ),
        },
        {
          path: "*",
          element: (
            <Suspense fallback={<MapOverlay />}>
              <NotFound />
            </Suspense>
          ),
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;

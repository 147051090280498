import HomeBanner from "../components/homePage/HomeBanner";
import CheckAvailability from "../components/homePage/CheckAvailability";
import FiberPlans from "../components/homePage/FibrePlans";
import WhyFiber from "../components/homePage/WhyFIbre";
import HeroSection from "../components/homePage/HeroSection";

function Home() {
  return (
    <div className="m-0">
      <HeroSection />
      <main className=" flex flex-col gap-28 items-center justify-between">
        <WhyFiber />
        <FiberPlans />
        <CheckAvailability />
      </main>
      <HomeBanner />
    </div>
  );
}

export default Home;



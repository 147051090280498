import ScrollerTop from "../utils/utilities";

 

 
const PrivacyPolicy = () => {
  ScrollerTop();

  return (
    <div className=" w-4/5 m-auto">
      <header className="page-header">
        <h3 className="text-tertiary capitalize">Privacy Policy</h3>
        <p className="px-4">
          This Privacy Policy has been compiled to better serve those who are
          concerned with how their personal data is being used by Tizeti.
          ‘Personal Data’ means any information relating to an identified or
          identifiable natural person; it can be anything from a name, address,
          a photo, an email address, bank details, posts on social networking
          websites, medical information, and other unique identifiers. This
          Privacy Policy governs your use of the software application (Wificall)
          for mobile devices that was created by Tizeti Network Limited. Please
          read this Privacy Policy carefully to get a clear understanding of how
          we collect, use, protect or otherwise handle your Personal Data in
          accordance with the use of the Wificall The Application is a basic
          description of the app (features, functionality and content).
        </p>
      </header>
      <ol className="list-decimal flex flex-col gap-4 ">
        <li>
          <p>
            What information does the Application obtain and how is it used?
          </p>

          <p className="w/4">
            User-Provided Information The Application obtains the information
            you provide when you download and register the Application.
            Registration with us is optional. However, please keep in mind that
            you may not be able to use some of the features offered by the
            Application unless you register with us.
          </p>
        </li>
        <li>
          <p>
            When you register with us and use the Application, you generally
            provide
          </p>
          <ol className="">
            <li>
              <strong>(a)</strong> Your name, email address, age, username,
              password and other registration information;{" "}
            </li>
            <li>
              <strong>(b)</strong>
              Transaction-related information, such as when you make purchases,
              respond to any offers, or download or use applications from us;
            </li>
            <li>
              {" "}
              <strong> (c)</strong> Information you provide us when you contact
              us for help;
            </li>
            <li>
              <strong>(d) </strong>
              Debit/credit card information for purchase and use of the
              Application, and;
            </li>
            <li>
              <strong>(e)</strong> Information you enter into our system when
              using the Application, such as contact information and project
              management information.
            </li>
            <li></li>
          </ol>
        </li>
        <li>
          <p>
            We may also use the information you provided us to contact you from
            time to time to provide you with important information, required
            notices and marketing promotions.
          </p>
        </li>
        <li>
          <p>Automatically Collected Information</p>
          <ul className="list-disc px-4 w-4/5">
            <li>
              In addition, the Application may collect certain information
              automatically, including,but not limited to, the type of mobile
              device you use, your mobile devices unique device ID, the IP
              address of your mobile device, your mobile operating system, the
              type of mobile Internet browsers you use, and information about
              the way you use the Application
            </li>
          </ul>
        </li>
        <li>
          <p>
            Does the Application collect precise real-time location information
            of the device?
          </p>
          <ul className="list-disc px-4 w-4/5">
            <li>
              This Application collects information about the location of your
              mobile device.
            </li>
          </ul>
        </li>
        <li>
          <p>
            Do third parties see and/or have access to information obtained by
            the Application?
          </p>

          <ul className="list-disc px-4 w-4/5">
            <li>
              Only aggregated, anonymized data is periodically transmitted to
              external services to help us improve the Application and our
              service. We will share your information with third parties only in
              the ways that are described in this privacy statement.
            </li>
          </ul>
        </li>
        <li>
          <p>
            We may disclose User Provided and Automatically Collected
            Information: as required by law, such as to comply with a subpoena
            or similar legal process.
          </p>
        </li>
        <li>
          <p>What are my opt-out rights?</p>
          <ul className="list-disc px-4 w-4/5">
            <li>
              when we believe in good faith that disclosure is necessary to
              protect our rights, protect your safety or the safety of others,
              investigate fraud, or respond to a government request; with our
              trusted services providers who work on our behalf, do not have an
              independent use of the information we disclose to them and have
              agreed to adhere to the rules set forth in this privacy statement.
              If Wificall is involved in a merger, acquisition, or sale of all
              or a portion of its assets, you will be notified via email and/or
              a prominent notice on our Web site of any change in ownership or
              uses of this information, as well as any choices you may have
              regarding this information. You can stop all collection of
              information by the Application easily by uninstalling the
              Application. You may use the standard uninstall processes as may
              be available as part of your mobile device or via the mobile
              application marketplace or network.
            </li>
          </ul>
        </li>
        <li>
          <p>
            Data Retention Policy, Managing Your Information • We will retain
            User-Provided data for as long as you use the Application and for a
            reasonable time thereafter. We will retain Automatically Collected
            information for up to 24 months and thereafter may store it in
            aggregate. If you’d like us to delete User Provided Data that you
            have provided via the Application, please contact us at
            wificall@tizeti.com and we will respond in a reasonable time. Please
            note that some or all of the User-Provided Data may be required in
            order for the Application to function properly.
          </p>
        </li>
        <li>
          <p>Children</p>
          <ul className="list-disc px-4 w-4/5">
            <li>
              We do not use the Application to knowingly solicit data from or
              market to children under the age of 13. If a parent or guardian
              becomes aware that his or her child has provided us with
              information without their consent, he or she should contact us at
              wificall@tizeti.com. We will delete such information from our
              files within a reasonable time.
            </li>
          </ul>
        </li>
        <li>
          <p>Security</p>
          <ul className="list-disc px-4 w-4/5">
            <li>
              We are concerned about safeguarding the confidentiality of your
              information. We provide physical, electronic, and procedural
              safeguards to protect the information we process and maintain. For
              example, your password is hashed and encrypted before storage and
              we cannot decrypt the password, the database holding all our user
              data is secured and redundancy is provided to prevent data loss or
              corruption. Please be aware that, although we endeavour to provide
              reasonable security for information we process and maintain, no
              security system can prevent all potential security breaches.˝
            </li>
          </ul>
        </li>
        <li>
          <p>Changes</p>
          <ul className="list-disc px-4 w-4/5">
            <li>
              This Privacy Policy may be updated from time to time for any
              reason. We will notify you of any changes to our Privacy Policy by
              posting the new Privacy Policy here and informing you via email or
              text message. You are advised to consult this Privacy Policy
              regularly for any changes, as continued use is deemed approval of
              all changes. You can check the history of this policy by clicking
              here.
            </li>
          </ul>
        </li>
        <li>
          <p>
            Public Space (Bulletin Boards, Chat Rooms and Third-Party Sites)
          </p>
          <ul className="list-disc px-4 w-4/5">
            <li>
              Information that customers disclose in a public space, including
              on any bulletin board, chat room or any site Wificall may host for
              you, is available to anyone else who visits that space. The brand
              cannot safeguard any information you disclose there.
            </li>
          </ul>
        </li>
        <li>
          <p>Your Consent</p>
          <ul className="list-disc px-4 w-4/5">
            <li>
              By using the application and in accordance with the Nigeria Data
              Protection Regulation, 2019, you are consenting to our processing
              of your information as set forth in this Privacy Policy now and as
              amended by us. “Processing,” means using cookies on a
              computer/hand-held device or using or touching information in any
              way, including, but not limited to, collecting, storing, deleting,
              using, combining and disclosing information, all of which
              activities will take place in Nigeria. If you reside outside your
              information will be transferred, processed and stored there under
              Nigeria’s privacy standards.
            </li>
          </ul>
        </li>
        <li>
          <p>Remedies</p>
          <ul className="list-disc px-4 w-4/5">
            <li>
              In the event of any violation of this Privacy Policy by Tizeti,
              the Customer shall be entitled to demand the return of all
              User-Provided Data collected and to demand the end of the use of
              any such data. This remedy shall be available from the time of
              breach of this Privacy Policy, provided the Customer has already
              sent a complaint to the contact provided below, which has not been
              responded to.
            </li>
          </ul>
        </li>
        <li>
          <p>Contact us</p>
          <ul className="list-disc px-4 w-4/5">
            <li>
              If you have any questions regarding privacy while using the
              Application, or have questions about our practices, please contact
              us via email at Hello@wificall.ng. Our consumer code of practice
              can be found here https://www.tizeti.com/consumer-code/
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
};

export default PrivacyPolicy;

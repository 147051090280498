import { forwardRef } from "react"; 

const Input = forwardRef(
  (
    {
      type,
      id,
      name,
      label,
      value,
      placeholder,
      data,
      onClick,
      onChange,
      disabled,
      defaultValue
 
    },
    ref
  ) => { 

    return (
      <div className="relative w-full">
        {data?.length > 0 && (
          <div className="flex flex-wrap gap-2 mb-[15px]">
     
          </div>
        )}
        <input
          type={type}
          value={value}
          ref={ref}
          defaultValue={defaultValue}
          name={name}
          disabled={disabled}
          onChange={onChange}
          className={`place-holder  ${disabled && "cursor-wait"}`}
          placeholder={placeholder}
          jsaction="paste:puy29d;"
          maxLength="2048"
          aria-autocomplete="both"
          aria-haspopup="false"
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          required
        />
      </div>
    );
  }
);

export default Input;

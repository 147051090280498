import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";

export const GetAvailabelAreas = () => {
  const response = () =>
    axios
      .get("https://api.tizeti.com/api/v1/fibre/fibrePoints")
      .then((response) => response?.data);

  const { data, isLoading: gettingAvailableAreas } = useQuery({
    queryKey: ["getAvailableAreas"],
    queryFn: response,
    staleTime: 60 * 1000,
    retry: 3,
    retryDelay: (attemptNumber) => Math.min(attemptNumber * 500, 20000),
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast.error("Error fetching available areas:", error);
    },
  });
  const availableAreas = data
    .filter((value) => value.areas[0].available === true)
    .map((polygon) =>
      polygon?.areas.map((coord) => [coord.latitude, coord.longitude])
    );
  const yetToBeAvailable = data
    .filter((value) => value.areas[0].available !== true)
    .map((polygon) =>
      polygon?.areas.map((coord) => [coord.latitude, coord.longitude])
    );
  return { availableAreas, yetToBeAvailable, gettingAvailableAreas };
};
import { IoMdInformationCircleOutline } from "react-icons/io";

const MapGuide = () => {
  return (
    <>
      <div
        className="bg-slate-600/60 absolute bottom-[4%] right-1 backdrop-blur-sm shadow-sm rounded-lg p-2 w-fit  ml-5"
        style={{ zIndex: "999" }}
      >
        <div className="flex items-center gap-3 text-white uppercase">
          <h3 className="font-bold text-2xl text-inherit"> Availability |</h3>

          <div className="flex gap-2 items-center">
            <div className="bg-[#098d04] h-3 w-3 rounded-sm aspect-square text-base "></div>
            :<p className=" font-normal">Available</p>
          </div>

          <div className="flex gap-2 items-center">
            <div className="bg-[#FFBF00] h-3 w-3 rounded-sm aspect-square text-base "></div>
            :<p className=" font-normal">Coming soon</p>
          </div>
        </div>
      </div>

      <div className="absolute bottom-0 right-0 z-[999999] flex items-center w-[30rem] bg-white text-black">
        <IoMdInformationCircleOutline className="text-2xl" />
        <p className="p-2 text-sm ">
          NOTE: Areas not colored are without coverage and not planned for yet
        </p>
      </div>
    </>
  );
};

export default MapGuide;

import { Link } from "react-router-dom";
import { Model1 } from "../../assets";

const CheckAvailability = () => {
  return (
    <>
      <div className="flex justify-between items-end w-4/5">
        <figure className="w-2/5 h-2/5 aspect-square hidden lg:block">
          <img
            src={Model1}
            alt="Fiber model"
            className="w-full h-full object-cover "
          />
        </figure>
        <article className="container gap-8 lg:w-2/5 place-self-center">
          <p className="text-dark-chocolate text-[1.25rem]">
            *Our FreeFiber plans are not available in all locations. Please
            check your address above to see availability in your area*
          </p>
          <header className="lg:w-3/5 flex items-end">
            <h3 className="text-tertiary">Check Map</h3>
          </header>
          <p className="lg:text-xl">
            Discover the extensive coverage of FreeFiber's Fiber Internet across
            key areas in Lagos. Check out the map to see if your location is
            within our network's embrace and experience the difference with
            FreeFiber
          </p>

          <div className="flex items-end justify-between">
            <figure className="w-2/5 aspect-square lg:hidden">
              <img
                src={Model1}
                alt="Fiber model"
                className="w-full object-cover h-full"
              />
            </figure>
            <Link to="/coverage">
              <button className="button-tertiary w-fit mb-4">
                Check Availability
              </button>
            </Link>
          </div>
        </article>
      </div>
    </>
  );
};

export default CheckAvailability;

import { useEffect } from "react";
import { useMap } from "react-leaflet";

import L from "leaflet";
import { Logo } from "../../assets";

export function ResetMapCenter(props) {
  const { centerPosition } = props;
  const map = useMap();

  useEffect(() => {
    if (centerPosition) {
      map.setView(
        L.latLng(centerPosition[0], centerPosition[1]),
        map.getZoom(),
        { animate: true }
      );
    }
  }, [centerPosition, map]);

  L.popup();
  return null;
}

export const availableFillColor = { fillColor: "#098d04" };
export const yetFillColor = { fillColor: "#FFBF00" };

export const MapOverlay = () => (
  <div className="h-[80vh]">
    <div className="absolute top-0 left-0 h-[85vh] z-[9999] w-screen bg-slate-500/50 flex justify-center items-center cursor-wait">
      <figure className="w-[15rem] rounded-lg overflow-hidden">
        <img
          src={Logo}
          alt="Logo"
          className=" w-full object-contain mix-blend-multiply animate-pulse"
        ></img>
      </figure>
    </div>
  </div>
);


import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { fiberPlans } from "../../utils/mocks/pricingMocks";

const FiberPlans = () => {
  return (
    <>
      <div className="flex flex-col gap-32 py-[1%] px-[10%]" id="plans">
        <header className="w-full lg:w-3/5 text-center m-auto">
          <h3 className="text-tertiary">
            Take a look at our Fiber Plans and pick which is best for your needs
          </h3>
        </header>
        <div className="flex flex-col lg:flex-row justify-between gap-8 ">
          {fiberPlans.map((item, index) => (
            <div
              id="card"
              key={index}
              className={` w-full lg:w-1/3 rounded-lg border-[1px] overflow-hidden ${
                item.tag ? "bg-primary-light text-white" : "border-gray-400"
              }`}
            >
              <Link
                className="flex items-center py-10 px-6 gap-2 font-bold w-full h-full"
                to="/signup"
                state={{ ...item }}
              >
                <div className="container gap-10 h-full justify-between ">
                  <article className="flex flex-col gap-5">
                    <header className="flex items-center justify-between relative">
                      <div className="">
                        <h3
                          className={`${
                            item.tag ? "text-tertiary-white" : "text-tertiary"
                          }`}
                        >
                          {item.title}
                        </h3>
                        <h6
                          className={`${
                            item.tag ? "text-white" : ""
                          } text-xl px-2`}
                        >
                          {item.speed}
                        </h6>
                      </div>

                      {item.tag && (
                        <p
                          className="bg-[#D9D9D9] py-2 px-4 text-xs custom-box-shadow text-black md:absolute md:-top-5 md:-right-5 md:scale-75"
                          id="card-tag"
                        >
                          {item.tag}
                        </p>
                      )}
                    </header>

                    <p
                      className={`lg:text-base ${
                        item.tag ? "" : "text-faded-black"
                      }`}
                    >
                      {item.caption}
                    </p>
                  </article>

                  <div className="flex flex-col gap-5">
                    <p>
                      <span className="text-2xl font-bold">{item.price}</span>
                      {/* <span> /mo </span> */}
                    </p>

                    <div className="flex items-center gap-2 font-bold">
                      <span
                        className={`text-base font-semibold ${
                          item.tag ? "border-white" : " border-primary-light"
                        } border-b-[2px]`}
                      >
                        Sign up Now
                      </span>
                      <FaArrowRightLong />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default FiberPlans;

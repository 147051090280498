import axios from "axios";
import { toast } from "react-toastify";
import {
  CHECK_AVAILABLE_DATES_URL,
  CITIES_STATES,
  GoogleaAPIKey,
  PAYSTACK_LIVE_KEY,
  REGIONS,
  REGISTER_NEWUSER,
  UPLOAD_VIDEO,
  VERIFICATION_URL,
} from "./services/config";
import { useQuery } from "@tanstack/react-query";
import * as geolib from "geolib";

//The endpoint to sign up A new User
export const RegisterNewUser = async (data) => {
  const response = axios
    .post(`${REGISTER_NEWUSER}`, { ...data })
    .then((res) => {
      if (res.status === 200) {
        toast.success(`User Registered`);
        sessionStorage.setItem("token", res.data.token);
        return res;
      }
    });

  return response;
};

//Upload the video on register Success
export const videoUpload = async (data) => {
  const config = {
    method: "post",
    url: `${UPLOAD_VIDEO}`,
    data,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
  };
  const response = axios(config).then((res) => {
    try {
      if (res.status === 200) {
        // toast.success(`Video  Uploaded`);
        return res;
      }
    } catch (e) {
      // Handle Error
    }
  });

  return response;
};

//Paystack Function
// Allows Switching forms and updating states
export const checkOut = (data, setSchedule, setInfo) => {
  const generated_reference = Math.floor(Math.random() * 1000000000 + 1);
  let ServiceHandler;
  ServiceHandler = window.PaystackPop.setup({
    key: `${PAYSTACK_LIVE_KEY}`,
    email: data.email,
    amount: Number(data?.amount) + "00",
    ref: "" + generated_reference,
    firstname: data.firstName,
    lastname: data.lastName,
    metadata: {
      custom_fields: [
        {
          email: data?.email,
          state: data?.state,
          payment_type: "Fiber installation",
          month: "1",
          amount: Number(data?.amount),
          firstname: data?.firstName,
          lastname: data?.lastName,
          phone: data?.phoneNumber,
        },
      ],
    },
    callback: function (response) {
      if (response.reference && response.status === "success") {
        const ref = response.reference;
        try {
          axios.get(`${VERIFICATION_URL}${ref}`, {}).then((res) => {
            if (res.data.data.status === "success") {
              toast.success("Transaction successful");
              setInfo((prev) => {
                return { ...prev, ...data, payment_referencey: ref };
              });
              setSchedule(true);
            } else {
              toast.info("Something went wrong!");
            }
          });
        } catch (e) {
          toast.info("Something went wrong!");
        }
      } else {
        toast.info("Transaction Failed");
      }
    },
    onClose: function () {},
  });
  ServiceHandler.openIframe();
};

// A function that returns two function
//1. Check Available dates
//2. Check Next Date
export const useCheckAvailableDates = () => {
  const getAvailableDates = async (datay) => {
    return axios.post(`${CHECK_AVAILABLE_DATES_URL}`, datay);
  };

  const getNextAvailableDates = async (nextdata) => {
    const res = await axios.post(`${CHECK_AVAILABLE_DATES_URL}`, nextdata);
    return res;
  };

  return {
    getAvailableDates,
    getNextAvailableDates,
  };
};

// The endpoint to schedule an intallation
export const Schedule = async (data, setIsScheduling) => {
  const Swal = require("sweetalert2");
  setIsScheduling(true);
  const config = {
    method: "post",
    url: `https://api.tizeti.com/api/v1/installation`,
    data,
    headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
  };
  try {
    await axios(config).then((response) => {
      if (response.data.id) {
        // toast.success("Scheduling Successful");
        Swal.fire({
          title: "Scheduling Successful!",
          text: "We have recieved your schedule and other information. A member of our team will contact you very soon! Thank you.",
          icon: "success",
          confirmButtonColor: "#1ac9d8",
        }).then(() => {
          setIsScheduling(false);
          window.location.replace("/");
        });
      }
    });
  } catch (error) {
    if (error) {
      toast.info("Something went wrong!");
      setIsScheduling(false);
    }
  }
};

// Get cities after State Picked
export function useGetCities(state) {
  const config = { headers: { "Content-Type": "application/json" } };

  const getCities = async () => {
    return await axios.get(
      `${CITIES_STATES + state.toLocaleLowerCase()}`,
      config
    );
  };

  const {
    isLoading: cityIsLoading,
    isError,
    data,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["getCities"],
    queryFn: () => getCities(state),
    enabled: false,
  });

  if (isError) {
    toast.warn("Something Went Wrong cannot fetch Cities Please try again");
  }

  return { cityIsLoading, data, refetch, isFetching };
}

// Get State after State Picked
export function useGetStates() {
  const config = { headers: { "Content-Type": "application/json" } };

  const getState = async () => {
    return axios.get(`${REGIONS}`, config);
  };

  const {
    isPending,
    data,
    isLoading: isFetchingState,
  } = useQuery({
    queryKey: ["getStates"],
    queryFn: () => getState(),
  });
  const filteredState = data?.data
    .filter((value) => value.country === "Nigeria")
    .map((item) => item.state);

  return { isPending, filteredState, isFetchingState };
}

// Handle Address Search
export async function handleAddressSearch(
  value,
  setMarkerPosition,
  setCoverageDetails,
  setIsLoading,
  yetToBeAvailable,
  availableAreas
) {
  const address = value.trim();
  let lat;
  let lng;

  setIsLoading(true);

  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
      )}&key=${GoogleaAPIKey}`
    );
    const data = await response.json();
    const location = data.results[0]?.geometry?.location;

    if (location) {
      lat = location.lat;
      lng = location.lng;
      setMarkerPosition((prev) => {
        return { ...prev, coordinate: [lat, lng] };
      });
    } else {
      toast.info("Location not found, please use a more descriptivre address");
    }

    checkAvailability(
      lat,
      lng,
      setCoverageDetails,
      availableAreas,
      yetToBeAvailable
    );
  } catch (error) {}
  setIsLoading(false);
}

const checkAvailability = (
  lat,
  lng,
  setCoverageDetails,
  availableAreas,
  yetToBeAvailable
) => {
  let fiberAvailability;
  let message;
  let successMessage;


  switch (true) {
    case availableAreas.some((coverageArea) =>
      geolib.isPointInPolygon({ latitude: lat, longitude: lng }, formattedCoordinates(coverageArea))
    ):
      fiberAvailability = "available";
      message = "Fiber is Available Here";
      successMessage = "Fiber is Available Here";
      break;

    case yetToBeAvailable.some((coverageArea) =>
      geolib.isPointInPolygon({ latitude: lat, longitude: lng }, formattedCoordinates(coverageArea))
    ):
      fiberAvailability = "yet_to_be_available";
      message = "Fiber is Coming Here soon..";
      successMessage = "Fiber is Coming Here soon..";
      break;

    default:
      fiberAvailability = "unavailable";
      message = "Fiber is Not Available Here";
      successMessage = "Fiber is Not Available Here";
      break;
  }

  setCoverageDetails({
    message: message,
    status:
      fiberAvailability === "available" ||
      fiberAvailability === "yet_to_be_available",
  });

  // if (
  //   fiberAvailability === "available" ||
  //   fiberAvailability === "yet_to_be_available"
  // ) {
  //   toast.success(successMessage);
  // } else {
  //   toast.info(successMessage);
  // }
};

const formattedCoordinates = (coordinatesArray) =>
  coordinatesArray.map((coord) => ({
    latitude: coord[0],
    longitude: coord[1],
  }));
